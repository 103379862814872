import React from "react";
import {
  MyTextInput,
  MyPhoneInput,
  MyEventDate,
  MySelect,
} from "./formikinputs/formikinputs";
import { Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { validationSchema } from "../../functions/validate";
import "./contactform.css";
import "react-toastify/dist/ReactToastify.css";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function ContactForm() {
  return (
    <div className="contact-form-wrapper">
      <ToastContainer />
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone1: "",
          phone2: "",
          phone3: "",
          eventDate: "", // not required
          eventLoc: "", // not required
          eventType: "",
          service: "", // required but must have 'i don't know yet' option
          msg: "", // not required
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": "contact",
              ...values,
            }),
          })
            .then(() => {
              toast.success(
                "Inquiry sent! We'll get back to you as soon as possible",
                { className: "contact-toast-success" }
              );
              resetForm();
              window.scrollTo(0, 0);

              setSubmitting(false);
            })
            .catch((error) => {
              toast.error("Error! Please try again or at a later time", {
                className: "contact-toast-error",
              });
              setSubmitting(false);
            });
        }}
      >
        <Form data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />.
          <MyTextInput label="Full Name *" name="name" type="text" />
          <MyTextInput label="Email *" name="email" type="text" />
          <MyPhoneInput
            firstSet={{
              label: "(###)",
              name: "phone1",
              type: "tele",
              maxLength: 3,
            }}
            secondSet={{
              label: "###",
              name: "phone2",
              type: "tele",
              maxLength: 3,
            }}
            thirdSet={{
              label: "####",
              name: "phone3",
              type: "tele",
              maxLength: 4,
            }}
          />
          <MyEventDate label="Date of Event" name="eventDate" type="date" />
          <MyTextInput label="Event Location" name="eventLoc" type="text" />
          <MySelect label="Event Type *" name="eventType">
            <option value="">Select an event type</option>
            <option value="Wedding">Wedding</option>
            <option value="Birthday">Birthday</option>
            <option value="Party">Party</option>
            <option value="Other">Other</option>
          </MySelect>
          <MySelect label="Service Package *" name="service">
            <option value="">Select a service package</option>
            <option value="Copper Package">Copper Package</option>
            <option value="Silver Package">Silver Package</option>
            <option value="Golden Package">Golden Package</option>
            <option value="Honeymoon Package">Honeymoon Package</option>
            <option value="Life Celebration Package">
              Life Celebration Package
            </option>
            <option value="Not sure yet">Not Sure Yet</option>
          </MySelect>
          <MyTextInput
            textArea={true}
            label="Additional Information or Questions"
            name="msg"
            type="text"
          />
          <button type="submit">Submit</button>
        </Form>
      </Formik>
    </div>
  );
}

export default ContactForm;
