import * as Yup from "yup";
import { createTomorrowDateString } from "./func";

const tomorrowDate = createTomorrowDateString();

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(35, "Must be 35 characters or less")
    .min(3, "Must be at least 3 characters")
    .required("Required*"),
  email: Yup.string().email("Invalid email address").required("Required*"),
  phone1: Yup.string()
    .matches(/^\d+$/, "Please enter digits only")
    .min(3, "Please provide a 10 digit phone number")
    .max(3, "Must be only 3 digits")
    .required("Required*"),
  phone2: Yup.string()
    .matches(/^\d+$/, "Please enter digits only")
    .min(3, "Please provide a 10 digit phone number")
    .max(3, "Must be only 3 digits")
    .required("Required*"),
  phone3: Yup.string()
    .matches(/^\d+$/, "Please enter digits only")
    .min(4, "Please provide a 10 digit phone number")
    .max(4, "Must be only 4 digits")
    .required("Required*"),
  eventDate: Yup.date().min(tomorrowDate, "Must be after today's date"),
  eventLoc: Yup.string()
    .max(50, "Must be 50 characters or less")
    .min(3, "Must be at least 3 characters"),
  eventType: Yup.string().required("Required*"),
  service: Yup.string().required("Required*"),
  msg: Yup.string()
    .max(1000, "Must be 1000 characters or less")
    .min(5, "Must be at least 5 characters"),
});

/*
      name: "",
      email: "",
      phone1: null,
      phone2: null,
      phone3: null,
      eventDate: todaysDate,
      eventLoc: "",
      eventType: null,
      service: null,
      msg: "",
*/
