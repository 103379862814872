import React from "react";
import PageTitle from "../component/pagetitle/pagetitle";
import ContactForm from "../component/contactform/contactform";
import ContactPic from "../assets/img/contact/contact-pic.jpg";
import imgTitleLarge from "../assets/img/contact/background-contact.jpg";
import imgUrlMed from "../assets/img/contact/background-contact-medium.jpg";
import imgUrlSmall from "../assets/img//contact/background-contact-small.jpg";
import "./contact.css";

const Contact = () => {
  return (
    <div>
      <PageTitle
        imgUrlLarge={imgTitleLarge}
        imgUrlMed={imgUrlMed}
        imgUrlSmall={imgUrlSmall}
        titleName={"Let's get in touch!"}
      />
      <div className="contact-wrapper">
        <ul className="contact-content">
          <li className="contact-title-and-form-wrapper">
            <h1>
              Contact <i>us</i>
            </h1>
            <p>
              For any and all questions regarding our services, please fill out
              the form below and it will be sent to our email!
            </p>
            <ContactForm />
          </li>
          <li
            className="contact-pic-wrapper"
            style={{ backgroundImage: `url(${ContactPic})` }}
          ></li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
