import React from "react";
import "./about.css";

function About() {
  return (
    <div className="about-wrapper">
      <div className="about-info-container">
        <div className="about-item-1">
          <p className="about-title">
            <i>meet becky song</i>
          </p>
          <div className="about-text-box">
            <h4 className="about-name-highlight">Becky</h4>
            <p className="about-text">
              always had a curiosity about event planning as she attended many
              weddings and parties with her friends and family. In 2015, she
              took steps to satiate her curiosity when she interned for a
              wedding planner. She fell in love with every aspect of the
              planning process and decided to branch into her own brand to help
              other couples and individuals realize their perfect day. Through
              the years, Becky has acquired an immeasurable amount of experience
              giving her the confidence to bring her client's dreams to life.
              She has developed such a love for the process that she would
              rather plan and coordinate an event than attend one as a guest! In
              her spare time, Becky enjoys spending time with her friends and
              family, though preferably while dining out. She also enjoys
              snowboarding in the winters, traveling all throughout the year,
              hiking in the summer, and smothering her cats (who unfortunately
              don't recipocrate the love as much).{" "}
            </p>
          </div>
        </div>
        <div className="about-item-2">
          <p className="about-title">
            <i>location</i>
          </p>
          <div className="about-text-box">
            <p className="about-text">
              I am based around Orange County, Southern California. But I am
              willing to do weddings in any location around the world! If you're
              not sure, contact us and send us an inquiry!{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
