export function ConvertDateToFormat(dateObject) {
  let month = dateObject.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }

  let day = dateObject.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  const fixedDate = dateObject.getFullYear() + "-" + month + "-" + day;
  return fixedDate;
}

export function createTomorrowDateString() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return ConvertDateToFormat(tomorrow);
}
