import React from "react";
import { useInView } from "react-intersection-observer";
import NavButton from "../component/navbutton/navbutton";
import "./home.css";
import homeGalImg1_1 from "../assets/img/home/home-gallery-1-1.jpg";
import homeGalImg1_2 from "../assets/img/home/home-gallery-1-2.jpg";
import homeGalImg1_3 from "../assets/img/home/home-gallery-1-3.jpg";
import homeSplash from "../assets/img/home/home-splash.jpg";

const options = { triggerOnce: true, threshold: 0.25 };

const Home = () => {
  const { ref: homeServicesRef, inView: homeServicesIsVis } =
    useInView(options);
  const { ref: gallImgRef1, inView: gallImg1IsVis } = useInView(options);
  const { ref: gallImgRef2, inView: gallImg2IsVis } = useInView(options);
  const { ref: gallImgRef3, inView: gallImg3IsVis } = useInView(options);
  return (
    <div className="home-wrapper">
      <div className="home-splash-cover">
        <img src={homeSplash} alt="bride and husband to be embrace"></img>
      </div>

      <div
        ref={homeServicesRef}
        className={
          "home-services" + (homeServicesIsVis ? " home-services-appear" : "")
        }
      >
        <ul>
          <li>
            <p>
              Hey there! You're likely here because you're either starting your
              wedding planning journey or preparing for an important event, and
              you've come to the right place! We understand that the planning
              process might be overwhelming, so please let us help you curate
              the most detailed timeline for your special day. Here at Planned
              by Becky, we strive for excellence and providing the best service
              to make everything as seamless and efficient as possible.
            </p>
          </li>
          <li>
            <NavButton buttonName="Services" buttonUrl="/services" />
          </li>
        </ul>
      </div>
      <ul className="home-gallery-first">
        <li>
          <img
            ref={gallImgRef1}
            className={
              "home-gallery-img" +
              (gallImg1IsVis ? " home-gallery-img-appear" : "")
            }
            src={homeGalImg1_1}
            alt="temp alt"
          />
        </li>
        <li>
          <img
            ref={gallImgRef2}
            className={
              "home-gallery-img" +
              (gallImg2IsVis ? " home-gallery-img-appear" : "")
            }
            src={homeGalImg1_2}
            alt="temp alt 2"
          />
        </li>
        <li>
          <img
            ref={gallImgRef3}
            className={
              "home-gallery-img" +
              (gallImg3IsVis ? " home-gallery-img-appear" : "")
            }
            src={homeGalImg1_3}
            alt="temp alt 3"
          />
        </li>
      </ul>
      <div className="home-spacer"></div>
    </div>
  );
};

export default Home;
