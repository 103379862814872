import React from "react";
import { useInView } from "react-intersection-observer";
import PageTitle from "../component/pagetitle/pagetitle";
import "./services.css";
import PckgImg_1 from "../assets/img/services/pckg-1.jpg";
import PckgImg_2 from "../assets/img/services/pckg-2.jpg";
import PckgImg_3 from "../assets/img/services/pckg-3.jpg";
import imgTitleLarge from "../assets/img/services/background-services.jpg";
import imgTitleMedium from "../assets/img/services/background-services-medium.jpg";
import imgTitleSmall from "../assets/img/services/background-services-small.jpg";

const options = { triggerOnce: true, threshold: 0.25 };

function Services() {
  const { ref: packageRef1, inView: packageRef1InView } = useInView(options);
  const { ref: packageRef2, inView: packageRef2InView } = useInView(options);
  const { ref: packageRef3, inView: packageRef3InView } = useInView(options);
  const { ref: packageRef4, inView: packageRef4InView } = useInView(options);
  const { ref: packageRef5, inView: packageRef5InView } = useInView(options);
  return (
    <div className="services-wrapper">
      <PageTitle
        imgUrlLarge={imgTitleLarge}
        imgUrlMed={imgTitleMedium}
        imgUrlSmall={imgTitleSmall}
        titleName={"SERVICES | Package Options"}
      />
      <div className="services-content">
        <div className="services-desc">
          <p>
            We understand that you might not need help with everything
            throughout your planning process, so we offer different package for
            your needs. Please look at the following packages to see what best
            fits your needs.
          </p>
        </div>

        <ul
          ref={packageRef1}
          className={
            "service-pckg left-img" +
            (packageRef1InView ? " service-pckg-appear" : "")
          }
        >
          <li className="pckg-img">
            <img src={PckgImg_1} alt="tableware on the day of a wedding"></img>
            <p className="pckg-img-numtext">01</p>
          </li>
          <li className="pckg-desc">
            <h2> Copper Package - Day of Coordination</h2>
            <p>
              This package caters towards the type of couples that have all of
              the planning under control and have mostly booked all of their
              vendors, but need help with managing the day of events and
              organizing the timeline for their big day. This will allow the
              couple to enjoy their special day without having to worry about
              the little details.{" "}
            </p>
          </li>
        </ul>
        <ul
          ref={packageRef2}
          className={
            "service-pckg left-img" +
            (packageRef2InView ? " service-pckg-appear" : "")
          }
        >
          <li className="pckg-desc">
            <h2>Silver Package - partial planning</h2>
            <p>
              This package will be ideal for couples who might want some
              additional help with vendor suggestions, color scheme, contract
              negotiation and more. We will provide you with some further
              support within your wedding planning process, so you have some
              peace of mind during this demanding time.
            </p>
          </li>
          <li className="pckg-img">
            <img src={PckgImg_2} alt="guest book for a wedding"></img>
            <p className="pckg-img-numtext">02</p>
          </li>
        </ul>
        <ul
          ref={packageRef3}
          className={
            "service-pckg left-img" +
            (packageRef3InView ? " service-pckg-appear" : "")
          }
        >
          <li className="pckg-img">
            <img
              src={PckgImg_3}
              alt="close up of wedding table with tableware and decorations"
            ></img>
            <p className="pckg-img-numtext">03</p>
          </li>
          <li className="pckg-desc">
            <h2> Golden Package - full planning</h2>
            <p>
              Just got engaged, but don’t really know where to start? Then this
              package will be most likely serve you well. We will help you with
              the planning process every step of the way, from planning to
              budgeting and design. We will ensure to select the best vendors
              and create the most seamless wedding planning experience to
              execute one of the most beautiful and celebrated days of life.
            </p>
          </li>
        </ul>
        <ul
          ref={packageRef4}
          className={
            "service-pckg left-img" +
            (packageRef4InView ? " service-pckg-appear" : "")
          }
        >
          <li className="pckg-desc">
            <h2> Honeymoon Package</h2>
            <p>
              It’s hard planning for a wedding already, so planning for a
              honeymoon will add some stress as well. Let us help you lift some
              of that burden off your shoulders and help you arrange hotels,
              transportation, flights and more. Leave the searching up to us, so
              you can relax and spend more time on the more important things in
              life. We could help you with as much or as little as you want in
              this process.
            </p>
          </li>
          <li className="pckg-img">
            <img src={PckgImg_2} alt="guest book for a wedding"></img>
            <p className="pckg-img-numtext">04</p>
          </li>
        </ul>
        <ul
          ref={packageRef5}
          className={
            "service-pckg left-img" +
            (packageRef5InView ? " service-pckg-appear" : "")
          }
        >
          <li className="pckg-img">
            <img
              src={PckgImg_3}
              alt="close up of wedding table with tableware and decorations"
            ></img>
            <p className="pckg-img-numtext">05</p>
          </li>
          <li className="pckg-desc">
            <h2>Life Celebration Package</h2>
            <p>
              We are not limited to weddings! We also offer our services to
              bring to life your vision of different celebrations in life such
              as birthdays, anniversaries, baby showers, bridal showers,
              proposals, and more!
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Services;
