import React from "react";
import "./couplegallerytitle.css";

function CoupleGalleryTitle(props) {
  return (
    <div className="couple-gallery-title-wrapper">
      <div
        style={{ backgroundImage: `url(${props.imgUrl})` }}
        className="couple-gallery-hero-img"
      >
        <p className="couple-gallery-hero-title">Deborah + Scott</p>
      </div>
    </div>
  );
}

export default CoupleGalleryTitle;
