import React from "react";
import CoupleGalleryTitle from "../component/couplegallerytitle/couplegallerytitle";
import ImageTrio from "../component/imagetrio/imagetrio";
import "./couplegallery.css";
import hero from "../assets/img/deborah-scott-gallery/hero.jpg";
import set1ImgLarge from "../assets/img/deborah-scott-gallery/set1large.jpg";
import set1ImgSmallTop from "../assets/img/deborah-scott-gallery/set1smallbottom.jpg";
import set1ImgSmallBottom from "../assets/img/deborah-scott-gallery/set1smalltop.jpg";
import set2ImgLarge from "../assets/img/deborah-scott-gallery/set2large.jpg";
import set2ImgSmallTop from "../assets/img/deborah-scott-gallery/set2smalltop.jpg";
import set2ImgSmallBottom from "../assets/img/deborah-scott-gallery/set2smallbottom.jpg";

function CoupleGallery() {
  return (
    <div className="couple-gallery-wrapper">
      <CoupleGalleryTitle imgUrl={hero} />
      <ImageTrio
        imgLarge={set1ImgLarge}
        altLarge="bridesmaid assisting bride put on dress"
        imgSmallTop={set1ImgSmallTop}
        altSmallTop="bridesmaid checking dress"
        imgSmallBottom={set1ImgSmallBottom}
        altSmallBottom="dress hanging over heels"
        imgLargeLeft={true}
      />
      <ImageTrio
        imgLarge={set2ImgLarge}
        altLarge="groomsman assisting groom put on suit jacket"
        imgSmallTop={set2ImgSmallTop}
        altSmallTop="groomsmen and groom smiling in a group"
        imgSmallBottom={set2ImgSmallBottom}
        altSmallBottom="bridesmaids and bride smiling at each other"
        imgLargeLeft={false}
      />
    </div>
  );
}

export default CoupleGallery;
