import React from "react";
import PropTypes from "prop-types";
import "./imagetrio.css";

function ImageTrio(props) {
  return (
    <div className="image-trio-wrapper">
      <div className="trio-container">
        <img
          className={
            props.imgLargeLeft ? "trio-img-large" : "trio-img-large-opp"
          }
          alt={props.altLarge}
          src={props.imgLarge}
        ></img>
        <img
          className={
            props.imgLargeLeft ? "trio-img-small-top" : "trio-img-small-top-opp"
          }
          alt={props.altSmallTop}
          src={props.imgSmallTop}
        ></img>
        <img
          className={
            props.imgLargeLeft
              ? "trio-img-small-bottom"
              : "trio-img-small-bottom-opp"
          }
          alt={props.altSmallBottom}
          src={props.imgSmallBottom}
        ></img>
      </div>
    </div>
  );
}

ImageTrio.propTypes = {
  imgLarge: PropTypes.string,
  imgSmallTop: PropTypes.string,
  imgSmallBottom: PropTypes.string,
  altLarge: PropTypes.string,
  altSmallTop: PropTypes.string,
  altSmallBottom: PropTypes.string,
};

export default ImageTrio;
