import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./pagetitle.css";

function PageTitle(props) {
  const [winWidth, setWinWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWinWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const imgLinks = {
    sizeLarge: { backgroundImage: `url(${props.imgUrlLarge})` },
    sizeMedium: { backgroundImage: `url(${props.imgUrlMed})` },
    sizeSmall: { backgroundImage: `url(${props.imgUrlSmall})` },
  };

  const titleImgSize = () => {
    if (winWidth > 1024) {
      return imgLinks.sizeLarge;
    } else if (winWidth > 640) {
      return imgLinks.sizeMedium;
    } else {
      return imgLinks.sizeSmall;
    }
  };

  return (
    <div className="page-title-wrapper">
      <div style={titleImgSize()} className="page-title-img">
        <div className="top-page-spacer"></div>
        <div className="bottom-page-spacer"></div>
      </div>
      <div className="page-title">
        <h1>{props.titleName}</h1>
      </div>
    </div>
  );
}

PageTitle.propTypes = {
  imgUrlLarge: PropTypes.string,
  imgUrlMed: PropTypes.string,
  imgUrlSmall: PropTypes.string,
  titleName: PropTypes.string,
};

export default PageTitle;
