import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./navbutton.css";

function NavButton(props) {
  const navigate = useNavigate();

  const navLinkNormal = (e) => {
    e.preventDefault();
    navigate(e.target.attributes.href.nodeValue);
    window.scrollTo(0, 0);
  };

  return (
    <a className="nav-button" href={props.buttonUrl} onClick={navLinkNormal}>
      {props.buttonName}
    </a>
  );
}

NavButton.propTypes = {
  buttonName: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default NavButton;
