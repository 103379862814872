import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./galleryitem.css";

function GalleryItem(props) {
  const navigate = useNavigate();

  const galImgOnClick = (e) => {
    e.preventDefault();
    const weddingUrl = props.weddingUrl;
    navigate(weddingUrl);
    window.scrollTo(0, 0);
  };

  return (
    <ul className="gallery-item-wrapper">
      <li className="gal-item-img-cont">
        <button onClick={galImgOnClick}>
          <img src={props.galImgUrl} alt="" />
        </button>
      </li>
      <li className="gal-item-desc-cont">
        <p className="gal-desc-text">{props.coupleNames}</p>
      </li>
      <li className="gal-item-loc-cont">
        <p className="gal-desc-loc">
          <i>Location: {props.location}</i>
        </p>
      </li>
    </ul>
  );
}

GalleryItem.propTypes = {
  weddingUrl: PropTypes.string,
  galImgUrl: PropTypes.string,
  coupleNames: PropTypes.string,
  location: PropTypes.string,
};

export default GalleryItem;
