import React from "react";
import "./formikinputs.css";
import { useField } from "formik";

const MyTextInput = ({ label, textArea, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="myTextInput-wrapper">
      <label htmlFor={props.id || props.name}>{label}</label>
      {textArea ? (
        <textarea
          rows="10"
          cols="40"
          className={
            "textarea-input" +
            (meta.touched && meta.error ? " textarea-input-error" : "")
          }
          {...field}
          {...props}
        ></textarea>
      ) : (
        <input
          className={
            "text-input" +
            (meta.touched && meta.error ? " text-input-error" : "")
          }
          {...field}
          {...props}
        />
      )}

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

MyTextInput.defaultProps = {
  textArea: false,
};

const MyPhoneInput = (props) => {
  const [field1, meta1] = useField(props.firstSet);
  const [field2, meta2] = useField(props.secondSet);
  const [field3, meta3] = useField(props.thirdSet);

  return (
    <div className="myPhoneInput-wrapper">
      <fieldset>
        <legend>Contact Phone *</legend>
        <div className="phoneSetInput-wrapper">
          <label htmlFor={props.firstSet.name}>{props.firstSet.label}</label>
          <input
            className={
              "phone-text-input" +
              (meta1.error && (meta1.touched || meta2.touched || meta3.touched)
                ? " phone-text-input-error"
                : "")
            }
            {...field1}
            {...props.firstSet}
          />
        </div>
        <div className="phoneSetInput-wrapper">
          <label htmlFor={props.secondSet.name}>{props.secondSet.label}</label>
          <input
            className={
              "phone-text-input" +
              (meta2.error && (meta1.touched || meta2.touched || meta3.touched)
                ? " phone-text-input-error"
                : "")
            }
            {...field2}
            {...props.secondSet}
          />
        </div>
        <div className="phoneSetInput-wrapper">
          <label htmlFor={props.thirdSet.name}>{props.thirdSet.label}</label>
          <input
            className={
              "phone-text-input" +
              (meta3.error && (meta1.touched || meta2.touched || meta3.touched)
                ? " phone-text-input-error"
                : "")
            }
            {...field3}
            {...props.thirdSet}
          />
        </div>
      </fieldset>
      {meta1.touched &&
      meta1.error &&
      (meta1.error === meta2.error ||
        meta1.error === meta3.error ||
        meta1.error !== meta2.error ||
        meta1.error !== meta3.error) ? (
        <div className="error 1">{meta1.error}</div>
      ) : null}
      {meta2.touched && meta2.error && meta2.error !== meta1.error ? (
        <div className="error 2">{meta2.error}</div>
      ) : null}
      {meta3.touched &&
      meta3.error &&
      meta3.error !== meta1.error &&
      meta3.error !== meta2.error ? (
        <div className="error 3">{meta3.error}</div>
      ) : null}
    </div>
  );
};

const MyEventDate = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="myEventDate-wrapper">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="date-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mySelect-wrapper">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        className={meta.touched && meta.error ? "select-error" : ""}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export { MyTextInput, MyPhoneInput, MyEventDate, MySelect };

/*

{meta1.touched && meta1.error ? (
        <div className="error">{meta1.error}</div>
      ) : null}
      {meta2.touched && meta2.error ? (
        <div className="error">{meta2.error}</div>
      ) : null}
      {meta3.touched && meta3.error ? (
        <div className="error">{meta3.error}</div>
      ) : null}


const PhoneSetInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="phoneSetInput-wrapper">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="phone-text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MyPhoneInput = (props) => {
  return (
    <div className="myPhoneInput-wrapper">
      <fieldset>
        <legend>Contact Phone *</legend>
        <PhoneSetInput label="(###)" name="phone1" type="tel" maxLength={3} />
        <PhoneSetInput label="###" name="phone2" type="tel" maxLength={3} />
        <PhoneSetInput label="####" name="phone3" type="tel" maxLength={4} />
      </fieldset>
    </div>
  );
};

*/
