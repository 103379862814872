import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const [toggleOn, setToggleOn] = useState(false);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [scrollPos, setScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    function handleResize() {
      setWinWidth(window.innerWidth);
    }

    function handleScroll() {
      setScrollPos(window.pageYOffset);
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return (_) => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const toggleMobileMenu = (e) => {
    e.preventDefault();
    const currToggle = toggleOn;

    setToggleOn(!currToggle);
  };

  const navLinkMobileClick = (e) => {
    const currToggle = toggleOn;

    setToggleOn(!currToggle);

    navigate(e.target.attributes.href.nodeValue);
    window.scrollTo(0, 0);
  };

  const navLinkNormal = (e) => {
    navigate(e.target.attributes.href.nodeValue);
    window.scrollTo(0, 0);
  };

  const checkWinSize = (e) => {
    e.preventDefault();
    if (winWidth > 640) {
      navLinkNormal(e);
    } else {
      navLinkMobileClick(e);
    }
  };

  return (
    /* removed  <nav className={"nav-wrapper" + (scrollPos > 200 ? " covered-nav" : "")}>
    to accomodate different background images */

    <nav className="nav-wrapper covered-nav">
      <div className="logo">
        <Link className="link-logo" to="/">
          PLANNED BY BECKY
        </Link>
      </div>
      <div
        onClick={toggleMobileMenu}
        className={"nav-hamburger" + (toggleOn ? " toggle" : "")}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <ul className={"nav-links" + (toggleOn ? " open" : "")}>
        <li className={"nav-link-item" + (toggleOn ? " fade" : "")}>
          <a href="/" onClick={checkWinSize}>
            home
          </a>
        </li>
        <li className={"nav-link-item" + (toggleOn ? " fade" : "")}>
          <a href="/services" onClick={checkWinSize}>
            services
          </a>
        </li>
        <li className={"nav-link-item" + (toggleOn ? " fade" : "")}>
          <a href="/about" onClick={checkWinSize}>
            about
          </a>
        </li>
        <li className={"nav-link-item" + (toggleOn ? " fade" : "")}>
          <a href="/gallery" onClick={checkWinSize}>
            gallery
          </a>
        </li>
        <li className={"nav-link-item" + (toggleOn ? " fade" : "")}>
          <a href="/contact" onClick={checkWinSize}>
            contact
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
