import React from "react";
import PageTitle from "../component/pagetitle/pagetitle";
import GalleryItem from "../component/galleryitem/galleryitem";
import "./gallery.css";
import imgTitleLarge from "../assets/img/gallery/background-gallery.jpg";
import imgUrlMed from "../assets/img/gallery/background-gallery-medium.jpg";
import imgUrlSmall from "../assets/img/gallery/background-gallery-small.jpg";
import DeborahScott from "../assets/img/gallery/gall-feat-deborah-scott.jpg";
import EunDaniel from "../assets/img/gallery/gall-feat-eun-daniel.jpg";
import DaphneLloyd from "../assets/img/gallery/gall-feat-daphne-lloyd.jpg";

const Gallery = () => {
  return (
    <div className="gallery-wrapper">
      <PageTitle
        imgUrlLarge={imgTitleLarge}
        imgUrlMed={imgUrlMed}
        imgUrlSmall={imgUrlSmall}
        titleName={"Past Weddings"}
      />
      <ul className="gallery-content">
        <li className="gallery-content-cont">
          <GalleryItem
            weddingUrl="/deborah-scott"
            galImgUrl={DeborahScott}
            coupleNames="Deborah + Scott"
            location="Irvine, CA"
          />
        </li>
        <li className="gallery-content-cont">
          <GalleryItem
            galImgUrl={EunDaniel}
            coupleNames="Eun + Daniel"
            location="DTLA, CA"
          />
        </li>
        <li className="gallery-content-cont">
          <GalleryItem
            galImgUrl={DaphneLloyd}
            coupleNames="Daphne + Lloyd"
            location="Fullerton, CA"
          />
        </li>
      </ul>
    </div>
  );
};

export default Gallery;
