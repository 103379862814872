import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import NavButton from "../navbutton/navbutton";
import InstagramLogo from "../../assets/img/instagram.png";

function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-content">
        <ul className="footer-social footer-item">
          <li>
            <p className="footer-title">Follow us</p>
            <a
              href="https://www.instagram.com/plannedbybecky"
              target="_blank"
              rel="noopener"
            >
              <img
                className="footer-insta-logo"
                src={InstagramLogo}
                alt="instagram logo"
              />
            </a>
          </li>
        </ul>
        <div className="footer-contact footer-item">
          <ul>
            <li>
              <p className="footer-title">Contact us</p>
            </li>
            <li>
              <Link to="/contact">Email through our form</Link>
            </li>
            <li>
              <p className="footer-text">Phone #: Currently Unavailable</p>
            </li>
            <li>
              <p className="footer-text">Contact us 24/7!</p>
            </li>
            <li>
              <p className="footer-text">Email, text, or call at any time</p>
            </li>
          </ul>
        </div>
        <ul className="footer-directory footer-item">
          <p className="footer-title">PLANNED BY BECKY</p>
          <li>
            <NavButton buttonUrl="/" buttonName="Home" />
          </li>
          <li>
            <NavButton buttonUrl="/services" buttonName="Services" />
          </li>
          <li>
            <NavButton buttonUrl="/about" buttonName="About" />
          </li>
          <li>
            <NavButton buttonUrl="/gallery" buttonName="Gallery" />
          </li>
          <li>
            <NavButton buttonUrl="/contact" buttonName="Contact" />
          </li>
        </ul>
        <p className="footer-copyright">© 2023 PLANNED BY BECKY</p>
      </div>
    </div>
  );
}

export default Footer;
